body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #282c34;
  padding: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: calc(1rem + 1vmin);
}

.App-header {
  margin-bottom: 4rem;
}

.App-headline{
  margin: 0 0 1rem;
  color: #CAD2C5;
  /* font-size: calc(3rem + 1vmin); */
}

.App-email{
  color: #068587;
  margin-bottom: 2rem;
  display: block;
}

.App-link {
  color: #068587;
  display: inline-block;
}

.social-icon{
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
}

.App-article{
  color: #CAD2C5;
}

a,
a:visited,
a:active{
  color: #068587;
}

 @media screen and (min-width: 850px) {
  .App {
    padding: 10% 25%;
  }
}

